@font-face {
    font-family: manrope;
    src: url('../asset/fonts/Manrope-Regular.ttf');
}
@font-face {
    font-family: inter;
    src: url('../asset/fonts/Inter-Medium.ttf');
}
@font-face {
    font-family: interBold;
    src: url('../asset/fonts/Inter-Bold.ttf');
}
@font-face {
    font-family: interSemi;
    src: url('../asset/fonts/Inter-SemiBold.ttf');
}
@font-face {
    font-family: sora;
    src: url('../asset/fonts/Sora-Regular.ttf');
}

/* * {
  scrollbar-width: thin;
} */
::-webkit-scrollbar {
    height: 12px;
    width: 4px;
    background: rgb(247, 229, 229);
}

::-webkit-scrollbar-thumb {
    background: #65819d;
}

::-webkit-scrollbar-corner {
    background: #000;
}
.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    background: #ffff;
}
.slider {
    background-color: blue;
}
.editorClassName {
    padding: 8px;
    min-height: 200px;
}
.test {
    width: 100%;
}
.locationIcon {
    width: 40px;
    height: 30px;
}
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
}
.cookieContainer {
    width: 200px;
    background-color: aqua;
}

.MuiDataGrid-iconSeparator {
    display: none !important;
}
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
}

.slick-slide .slick-arrow .slick-prev {
    display: block !important;
}

.react__slick__slider__parent {
    position: relative;
}

.react__slick__slider__parent .slick-prev,
.react__slick__slider__parent .slick-next {
    position: absolute;
    top: 50%;
    right: 10px;
}
.react__slick__slider__parent .slick-prev {
    position: absolute;

    z-index: 1;
    left: 10px;
}
.react__slick__slider__parent .slick-next {
    border-radius: 50%;
    color: black;
    z-index: 1;
}

.react__slick__slider__parent .slick-prev:before,
.slick-next:before {
    color: black;
}
.react__slick__slider__parent .slick-next:before,
.slick-next:before {
    color: black;
}
/* ------------------------------------------ */

.react__slick__slider__parent2 {
    position: relative;
}

.react__slick__slider__parent2 .slick-prev,
.react__slick__slider__parent2 .slick-next {
    position: absolute;
    top: 50%;
    right: 10px;
}
.react__slick__slider__parent2 .slick-prev {
    position: absolute;

    z-index: 1;
    left: 10px;
}
.react__slick__slider__parent2 .slick-next {
    border-radius: 50%;
    color: black;
    z-index: 1;
}

.react__slick__slider__parent2 .slick-prev:before,
.slick-next:before {
    color: black;
}
.react__slick__slider__parent2 .slick-next:before,
.slick-next:before {
    color: black;
}

.showOtherPeopleIfRunOutAgeText > .MuiFormControlLabel-label {
    line-height: 1.2 !important;
    font-size: 13px !important;
}
.showOtherPeopleIfRunOutAgeText {
    margin-top: -10px !important;
    margin-left: -10px !important;
}

.showOtherPeopleIfRunOutDistanceText > .MuiFormControlLabel-label {
    line-height: 1.2 !important;
    font-size: 13px !important;
}
.showOtherPeopleIfRunOutDistanceText {
    margin-top: -10px !important;
    margin-left: -10px !important;
}
.dealBreakersText {
    align-self: center !important;
}
